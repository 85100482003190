import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


const slides = [
    "https://images.unsplash.com/photo-1590523277543-a94d2e4eb00b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80",
    "https://images.unsplash.com/photo-1622890806166-111d7f6c7c97?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80",
    "https://images.unsplash.com/photo-1540206351-d6465b3ac5c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=160&q=80",
]

export const Project = () => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

    return (
    <div>
      <div className="grid grid-row-2">
        <div>
          <h1 className=" text-left pt-16 w-full mb-4 text-4xl font-extrabold md:text-5xl xl:text-6xl">
            Projects
          </h1>
        </div>
        <div className="grid grid-cols-2">
          <div className="w-3/4 m-auto">
            <div className="m-5 justify-center items-center">
                <Slider {...settings}>

                {slides.map((d) => (
                    <div>
                        <img src={d} className="h-[100%] w-[100%]" />
                    </div>
                )) }
                </Slider>
            
            </div> 
          </div>
          <div className="m-5">Snap Synk is Video Production Colobration tools that bring everyone on one platform who is involved in video production team</div>
        </div>
      </div>
    </div>
  );
};
