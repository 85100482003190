import React, { useState } from "react";
import riyal from "../Assets/riyal.png";
import SnapSynk from "../Assets/SnapSynk.svg";
import RSC from "../Assets/RSC.svg";
import aos from "aos";
import CMDb from "../Assets/CMDb.svg";

const Projects = () => {
  useState(() => {
    aos.init({ duration: 1000 });
  });

  return (
    <section className="lg:h-screen md:h-screen pt-16" id="Projects">
      <h1 className="text-center pt-1 w-full mb-4 text-4xl font-extrabold leading-none md:text-5xl xl:text-6xl">Projects</h1>
      <div class="grid grid-cols-2 gap-4 md:grid-cols-4">
        <div
          data-aos="fade-up"
          data-aos-duration="2000"
          className="shadow p-3 hover:shadow-lg hover:-translate-y-6"
        >
          <a href="/project">
            <img className="rounded-t-lg" src={SnapSynk} />
          </a>
          <div>
            <a href="#">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                SnapSynk
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Here are the biggest enterprise technology acquisitions of 2021 so
              far, in reverse chronological order.
            </p>
            <a
              href="/project"
              className="hover:motion-safe:animate-bounce inline-flex items-center border-2 border-black px-3 py-2 text-sm font-medium text-center text-black border-2 rounded-lg hover:bg-black hover:text-white hover:font-bold"
            >
              Explore
              <svg
                class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="4000"
          className="shadow p-3 hover:shadow-lg hover:-translate-y-6"
        >
          <a href="#">
            <img className="p-2 rounded-t-lg" src={RSC} />
          </a>
          <div>
            <a href="#">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                Ride Share
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Here are the biggest enterprise technology acquisitions of 2021 so
              far, in reverse chronological order.
            </p>
            <a
              href="#"
              class="inline-flex items-center border-2 border-black px-3 py-2 text-sm font-medium text-center text-black border-2 rounded-lg hover:bg-black hover:text-white  hover:font-bold"
            >
              Explore
              <svg
                class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="3500"
          className="shadow p-3 hover:shadow-lg hover:-translate-y-6"
        >
          <a href="#">
            <img className=" m-8 rounded-t-lg" src={CMDb} />
          </a>
          <div>
            <a href="#">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                Chat My DB
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Here are the biggest enterprise technology acquisitions of 2021 so
              far, in reverse chronological order.
            </p>
            <a
              href="#"
              class="inline-flex items-center border-2 border-black px-3 py-2 text-sm font-medium text-center text-black border-2 rounded-lg hover:bg-black hover:text-white hover:font-bold"
            >
              Explore
              <svg
                class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="3700"
          className="shadow p-3 hover:shadow-lg hover:-translate-y-6"
        >
          <a href="#">
            <img className="p-2 rounded-t-lg" src={RSC} />
          </a>
          <div>
            <a href="#">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                Ride Share
              </h5>
            </a>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              Here are the biggest enterprise technology acquisitions of 2021 so
              far, in reverse chronological order.
            </p>
            <a
              href="#"
              class="inline-flex items-center border-2 border-black px-3 py-2 text-sm font-medium text-center text-black border-2 rounded-lg hover:bg-black hover:text-white hover:font-bold"
            >
              Explore
              <svg
                class="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;