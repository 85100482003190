import React, { useState } from "react";
import aos from "aos";

const Contact = () => {
    useState(() => {
      aos.init({ duration: 2000 });
    });
  return (
    <div>
      <section className="h-screen " id="Contact">
        <h1 className="text-center pt-16 w-full mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
          Say Hello !!
        </h1>
        <div className="mt-10">
            <p data-aos="slide-up" className="text-2xl m-10 p-10 select-text">
                <a href="mailto:riyalp99@gmail.com">
                    riyalp99@gmail.com
                </a>
            </p>
            <p data-aos="slide-up" className="text-2xl m-10 p-10">
                <a href="https://www.linkedin.com/in/riyalp/" target="_blank">
                    LinkedIn
                </a>
            </p>
            <p data-aos="slide-up" className="text-2xl m-10 p-10">
                <a href="https://github.com/BeRiyal" target="_blank">
                    GitHub
                </a>
            </p>
        </div>
      </section>
    </div>  
  );
};

export default Contact;
