import React from "react";
import Navbar from './Navbar';
import { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import Home from "./Home";

export const Layout = () => {
  return (
    <div>
      <div className="select-none m-3">
        <Navbar />        
        <Outlet />

      </div>
    </div>
  );
};
