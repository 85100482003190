import React, { useState } from "react";
import riyalImg from "../Assets/riyal.png";
import aos from "aos";
import { ReactTyped } from "react-typed";
import "../App.css";

const Hero = () => {
  const [fontFamily, setFontFamily] = useState("Arial");
  const [Titles, setTitles] = useState(["Hello"]);

  const handleStringTyped = () => {
    setTitles([""]);
    // Function to change font family
    const fontFamilies = [
      "Annapurna_SIL",
      "Rubik_Doodle_Shadow",
      "Libre_Barcode_128",
      "Jersey_25",
      "Fira_Code",
    ]; // Example font families
    const randomIndex = Math.floor(Math.random() * fontFamilies.length);
    const randomFontFamily = fontFamilies[randomIndex];
    setFontFamily(randomFontFamily);
    setTitles(["Riyal Patel", "Web Developer"]);
  };
  useState(() => {
    aos.init({ duration: 2000 });
  });
  return (
    <section className="h-screen" id="Home">
      <div className="flex gap-4 p-10 mt-auto flex-col-reverse lg:flex-row lg:justify-between ">
        <div className="place-self-center lg:mt-16 lg:ml-16">
          <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
            <ReactTyped
              strings={Titles}
              typeSpeed={150}
              loop
              onStringTyped={handleStringTyped} // Call handleStringTyped function when string typed
              style={{ fontFamily }} // Apply dynamic font family
            />
          </h1>
          <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl ">
            <div className="flex  justify-center flex-row">
              <div data-aos="slide-right" className="m-1">
                Python
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                Django
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                Js
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                ReactJs
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                Node/Express
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                Mobile
              </div>
            </div>
            <div className="flex  justify-center flex-row">
              <div data-aos="slide-right" className="m-1">
                Android
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                Java
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                MVVM
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                PhP
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                SQL
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                MangoDB
              </div>
            </div>
            <div className="flex justify-center flex-row">
              <div data-aos="slide-right" className="m-1">
                CSS3
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                Bootstrap
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                Aws
              </div>
              <div className="m-1">|</div>
              <div data-aos="slide-right" className="m-1">
                Power Shell
              </div>
            </div>
            <div></div>
          </p>
        </div>
        <div data-aos="fade" className="place-self-center">
          <img src={riyalImg} style={{ "mix-blend-mode": "multiply" }} />
        </div>
      </div>
    </section>
  );
};

export default Hero;
