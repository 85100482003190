import logo from './logo.svg';
import './App.css';
import '../src/index.css'
import {Project} from './components/Project';
import { Layout } from './components/Layout';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Home from './components/Home';

function App() {
  


  return (

    <BrowserRouter>
      <Routes>
        <Route path="" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="/project" element={<Project />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
