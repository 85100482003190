import React, { useEffect, useState } from "react";

export const Skills = () => {
  const getRandomSize = () => {
    const sizes = ["text-md", "text-lg", "text-xl", "text-2xl", "text-3xl"];
    return sizes[Math.floor(Math.random() * sizes.length)];
  };
  const getRandomDuration = () => {
    return Math.floor(Math.random() * 3000) + 1000; // Random duration between 1000 and 4000 milliseconds
  };

  // State to hold used durations
  const [usedDurations, setUsedDurations] = useState([]);

  // Effect to generate new unique durations when the component mounts
  useEffect(() => {
    const durations = new Set(); // Use a Set to ensure uniqueness
    while (durations.size < Webtechnologies.length) {
      durations.add(getRandomDuration());
    }
    setUsedDurations(Array.from(durations));
  }, []);
  const Webtechnologies = [
    "HTML",
    "CSS",
    "JavaScript",
    "ReactJS",
    "Django",
    "PHP",
    "MVC",
    "Node/ExpressJs",
    "RESTful API",
    "MongoDB",
    "PostgreSQL",
    "MySQL",
  ];
  const MobileApplications = [
    "Android",
    "Java",
    "SQLite",
    "API Integration",
    "Google API",
    "Sequrity",
    "XML",
    "MUI",
  ];
  const versionControll = [
    "Git",
    "GitHub",
    "DevOps",
    "Trello",
    "Continuous Integration",
    "Continuous Developnment",
    "Code Review",
    "Documentation",
  ];
  const interpersonal = [
    "Communication",
    "Collaboration",
    "Teamwork",
    "Quick Learning",
    "Adaptability",
    "Analitical Thinking",
  ];
  const cloud = ["AWS", "S3", "Elastic Beanstalk", "AWS Lambda"];
  const getRandomPosition = () => {
    return {
      colStart: Math.floor(Math.random() * 5) + 1,
      colSpan: Math.floor(Math.random() * 2) + 1,
      rowStart: Math.floor(Math.random() * 5) + 1,
    };
  };
  return (
    <>
      <section className="lg:h-screen" id='Skills'>
        <h1 className="text-center pt-1 w-full mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
          Skills <span className="text-xl font-semibold">
            (Technologies | Tools)
            </span>
        </h1>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
          <div className="">
            <div class="p-5">
              <a href="#">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                  Interpersonal Skills
                </h5>
              </a>
              <div className="circle-grid">
                {interpersonal.map((interpersonal, index) => (
                  <div
                    data-aos="zoom-in"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay={getRandomDuration()}
                    key={index}
                    className={`circle-item  hover:bg-black hover:text-white ${getRandomSize()}`}
                    style={getRandomPosition()}
                  >
                    {interpersonal}
                  </div>
                ))}
              </div>{" "}
            </div>
          </div>
          <div className="">
            <div class="p-5">
              <a href="#">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                  Mobile Applications
                </h5>
              </a>
              <div className="circle-grid">
                {MobileApplications.map((MobileApplications, index) => (
                  <div
                    data-aos="zoom-in"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay={getRandomDuration()}
                    key={index}
                    className={`circle-item hover:bg-black hover:text-white ${getRandomSize()}`}
                    style={getRandomPosition()}
                  >
                    {MobileApplications}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="">
            <div class="p-5">
              <a href="#">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                  Webtechnologies
                </h5>
              </a>
              <div className="circle-grid">
                {Webtechnologies.map((Webtechnologies, index) => (
                  <div
                    data-aos="zoom-in"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay={getRandomDuration()}
                    key={index}
                    className={`circle-item hover:bg-black hover:text-white ${getRandomSize()}`}
                    style={getRandomPosition()}
                  >
                    {Webtechnologies}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="">
            <div class="p-5">
              <a href="#">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                  Version Control & WorkFlow
                </h5>
              </a>
              <div className="circle-grid">
                {versionControll.map((versionControll, index) => (
                  <div
                    data-aos="zoom-in"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay={getRandomDuration()}
                    key={index}
                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

                    className={`circle-item hover:bg-black hover:text-white ${getRandomSize()}`}
                    style={getRandomPosition()}
                  >
                    {versionControll}
                  </div>
                ))}
              </div>{" "}
            </div>
          </div>
          <div className="">
            <div class="p-5">
              <a href="#">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                  Cloud
                </h5>
              </a>
              <div className="circle-grid">
                {cloud.map((cloud, index) => (
                  <div
                    data-aos="zoom-in"
                    data-aos-easing="ease-in-sine"
                    data-aos-delay={getRandomDuration()}
                    key={index}
                    className={`circle-item  hover:bg-black hover:text-white ${getRandomSize()}`}
                    style={getRandomPosition()}
                  >
                    {cloud}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
